import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  purchaseBillList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
    track_key: ""
  },
  purchaseBillListFilter: {},
  isLoadingPurchaseBillList: false,
  isLoadPurchaseBillListDone: false,
  isRefreshingPurchaseBillList: false, 

  purchaseBillDumpExpress: null,
  purchaseBillDumpRecycling: null,
  isLoadingPurchaseBill: false,
  
  purchaseNoteList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
    track_key: ""
  },
  purchaseNoteListFilter: {},
  isLoadingPurchaseNoteList: false,
  isLoadPurchaseNoteListDone: false,
  isRefreshingPurchaseNoteList: false, 

  purchaseNotePDF: null,
  isLoadingPurchaseNote: false,

  storeReceiptList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
    track_key: ""
  },
  storeReceiptListFilter: {},
  isLoadingStoreReceiptList: false,
  isLoadStoreReceiptListDone: false,
  isRefreshingStoreReceiptList: false, 
  storeReceiptDumpExpress: null,
  storeReceiptDumpRecycling: null,
  isLoadingStoreReceipt: false,
  
  wasteReceiptList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
    track_key: ""
  },
  wasteReceiptListFilter: {},
  isLoadingWasteReceiptList: false,
  isLoadWasteReceiptListDone: false,
  isRefreshingWasteReceiptList: false, 
  wasteReceiptDumpWaste: null,
  isLoadingWasteReceipt: false,
  purchaseBillMonth: {},
  isLoadingPurchaseBillMonth: false,
  purchaseBillSummary: null,
  isLoadingPurchaseBillSummary: false,

  
  stockRecordSummary: null,
  isLoadingStockRecordSummary: true,
  storeRecordList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false
  },
  isLoadingStoreRecordList: false,
  isRefreshingStoreRecordList: false, 
  
  monthlyStockReportList: [],
  isLoadingMonthlyStockReportList: false,
  isLoadingMonthlyStockReportDoc: false,
  
  monthlyWasteReportList: [],
  isLoadingMonthlyWasteReportList: false,
  isLoadingMonthlyWasteReportDoc: false,

  dailySettlement: null,
  isLoadingDailySettlementDetail: false,

  isUpdateDailySettlementSuccess: false,
  isUpdateDailySettlementFailed: false,
  isProcessUpdateDailySettlement: false,
};

export default function ReportReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.LOAD_PURCHASE_BILL_LIST:{
      let purchaseBillList = cloneDeep(state.purchaseBillList);
  
      purchaseBillList.isLoading = true;

      if (action.data.offset === 0) {
        purchaseBillList.data = [];
      }

      return {
        ...state,
        isLoadingPurchaseBillList: true,
        isLoadPurchaseBillListDone: false,
        purchaseBillList,
        isRefreshingPurchaseBillList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_LIST_SUCCESS: {
      let purchaseBillList = {};
      
      if (action.data.limit === undefined) {
        purchaseBillList = cloneDeep(state.purchaseBillList);
        purchaseBillList.data = action.data.data;
      } else {
        purchaseBillList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          purchaseBillList.data = action.data.data;
          purchaseBillList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            purchaseBillList.data = [...state.purchaseBillList.data];
            purchaseBillList.offset = action.data.offset;
          } else {
            let temp = [...state.purchaseBillList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            purchaseBillList.data = temp;
            purchaseBillList.length = state.purchaseBillList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          purchaseBillList.offset = purchaseBillList.length;
        }
      }
  
      purchaseBillList.isLoading = false;
      purchaseBillList.total = action.data.total;
      purchaseBillList.hasMore = false;
      
      if(purchaseBillList.length < purchaseBillList.total)
      {
        purchaseBillList.hasMore = true;
      }

      return {
        ...state,
        isLoadingPurchaseBillList: false,
        isLoadPurchaseBillListDone: true,
        purchaseBillList,
        purchaseBillListFilter: action.data
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_LIST_FAIL:{
      let purchaseBillList = cloneDeep(state.purchaseBillList);
      purchaseBillList.isLoading = false;

      return {
        ...state,
        purchaseBillList,
        isLoadingPurchaseBillList: false,
        isLoadPurchaseBillListDone: true,
      };
    }



    case actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS: {
      return {
        ...state,
        purchaseBillDumpExpress: null,
        isLoadingPurchaseBill: true,
      };
    }

    case actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS_SUCCESS: {
      return {
        ...state,
        purchaseBillDumpExpress: action.data,
        isLoadingPurchaseBill: false,
      };
    }

    case actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS_FAIL: {
      return {
        ...state,
        isLoadingPurchaseBill: false,
      };
    }    
    

    case actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING: {
      return {
        ...state,
        purchaseBillDumpRecycling: null,
        isLoadingPurchaseBill: true,
      };
    }

    case actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING_SUCCESS: {
      return {
        ...state,
        purchaseBillDumpRecycling: action.data,
        isLoadingPurchaseBill: false,
      };
    }

    case actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING_FAIL: {
      return {
        ...state,
        isLoadingPurchaseBill: false,
      };
    }    
    

    case actionTypes.LOAD_PURCHASE_NOTE_LIST:{
      let purchaseNoteList = cloneDeep(state.purchaseNoteList);
  
      purchaseNoteList.isLoading = true;

      if (action.data.offset === 0) {
        purchaseNoteList.data = [];
      }

      return {
        ...state,
        isLoadingPurchaseNoteList: true,
        isLoadPurchaseNoteListDone: false,
        purchaseNoteList,
        isRefreshingPurchaseNoteList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_PURCHASE_NOTE_LIST_SUCCESS: {
      let purchaseNoteList = {};
      
      if (action.data.limit === undefined) {
        purchaseNoteList = cloneDeep(state.purchaseNoteList);
        purchaseNoteList.data = action.data.data;
      } else {
        purchaseNoteList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          purchaseNoteList.data = action.data.data;
          purchaseNoteList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            purchaseNoteList.data = [...state.purchaseNoteList.data];
            purchaseNoteList.offset = action.data.offset;
          } else {
            let temp = [...state.purchaseNoteList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            purchaseNoteList.data = temp;
            purchaseNoteList.length = state.purchaseNoteList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          purchaseNoteList.offset = purchaseNoteList.length;
        }
      }
  
      purchaseNoteList.isLoading = false;
      purchaseNoteList.total = action.data.total;
      purchaseNoteList.hasMore = false;
      
      if(purchaseNoteList.length < purchaseNoteList.total)
      {
        purchaseNoteList.hasMore = true;
      }

      return {
        ...state,
        isLoadingPurchaseNoteList: false,
        isLoadPurchaseNoteListDone: true,
        purchaseNoteList,
        purchaseNoteListFilter: action.data
      };
    }

    case actionTypes.LOAD_PURCHASE_NOTE_LIST_FAIL:{
      let purchaseNoteList = cloneDeep(state.purchaseNoteList);
      purchaseNoteList.isLoading = false;

      return {
        ...state,
        purchaseNoteList,
        isLoadingPurchaseNoteList: false,
        isLoadPurchaseNoteListDone: true,
      };
    }



    case actionTypes.GET_PURCHASE_NOTE_PDF: {
      return {
        ...state,
        purchaseNotePDF: null,
        isLoadingPurchaseNote: true,
      };
    }

    case actionTypes.GET_PURCHASE_NOTE_PDF_SUCCESS: {
      return {
        ...state,
        purchaseNotePDF: action.data,
        isLoadingPurchaseNote: false,
      };
    }

    case actionTypes.GET_PURCHASE_NOTE_PDF_FAIL: {
      return {
        ...state,
        isLoadingPurchaseNote: false,
      };
    }    
    

    
    
    case actionTypes.LOAD_STORE_RECEIPT_LIST:{
      let storeReceiptList = cloneDeep(state.storeReceiptList);
  
      storeReceiptList.isLoading = true;

      if (action.data.offset === 0 || storeReceiptList.track_key !== action.data.track_key) {
        storeReceiptList.data = [];
      }

      return {
        ...state,
        isLoadingStoreReceiptList: true,
        isLoadStoreReceiptListDone: false,
        storeReceiptList,
        isRefreshingStoreReceiptList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_STORE_RECEIPT_LIST_SUCCESS: {
      let storeReceiptList = {};
      
      if (action.data.limit === undefined) {
        storeReceiptList = cloneDeep(state.storeReceiptList);
        storeReceiptList.data = action.data.data;
      } else {
        storeReceiptList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          storeReceiptList.data = action.data.data;
          storeReceiptList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            storeReceiptList.data = [...state.storeReceiptList.data];
            storeReceiptList.offset = action.data.offset;
          } else {
            let temp = [...state.storeReceiptList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            storeReceiptList.data = temp;
            storeReceiptList.length = state.storeReceiptList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          storeReceiptList.offset = storeReceiptList.length;
        }
      }
  
      storeReceiptList.isLoading = false;
      storeReceiptList.total = action.data.total;
      storeReceiptList.hasMore = false;
      
      if(storeReceiptList.length < storeReceiptList.total)
      {
        storeReceiptList.hasMore = true;
      }

      return {
        ...state,
        isLoadingStoreReceiptList: false,
        isLoadStoreReceiptListDone: true,
        storeReceiptList,
        storeReceiptListFilter: action.data
      };
    }

    case actionTypes.LOAD_STORE_RECEIPT_LIST_FAIL:{
      let storeReceiptList = cloneDeep(state.storeReceiptList);
      storeReceiptList.isLoading = false;

      return {
        ...state,
        storeReceiptList,
        isLoadingStoreReceiptList: false,
        isLoadStoreReceiptListDone: true,
      };
    }



    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS: {
      return {
        ...state,
        storeReceiptDumpExpress: null,
        isLoadingStoreReceipt: true,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_SUCCESS: {
      return {
        ...state,
        storeReceiptDumpExpress: action.data,
        isLoadingStoreReceipt: false,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_FAIL: {
      return {
        ...state,
        isLoadingStoreReceipt: false,
      };
    }    


    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING: {
      return {
        ...state,
        storeReceiptDumpRecycling: null,
        isLoadingStoreReceipt: true,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_SUCCESS: {
      return {
        ...state,
        storeReceiptDumpRecycling: action.data,
        isLoadingStoreReceipt: false,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_FAIL: {
      return {
        ...state,
        isLoadingStoreReceipt: false,
      };
    }    
    

    
    
    case actionTypes.LOAD_WASTE_RECEIPT_LIST:{
      let wasteReceiptList = cloneDeep(state.wasteReceiptList);
  
      wasteReceiptList.isLoading = true;

      if (action.data.offset === 0 || wasteReceiptList.track_key !== action.data.track_key) {
        wasteReceiptList.data = [];
      }

      return {
        ...state,
        isLoadingwasteReceiptList: true,
        isLoadwasteReceiptListDone: false,
        wasteReceiptList,
        isRefreshingwasteReceiptList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_WASTE_RECEIPT_LIST_SUCCESS: {
      let wasteReceiptList = {};
      
      if (action.data.limit === undefined) {
        wasteReceiptList = cloneDeep(state.wasteReceiptList);
        wasteReceiptList.data = action.data.data;
      } else {
        wasteReceiptList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          wasteReceiptList.data = action.data.data;
          wasteReceiptList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            wasteReceiptList.data = [...state.wasteReceiptList.data];
            wasteReceiptList.offset = action.data.offset;
          } else {
            let temp = [...state.wasteReceiptList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            wasteReceiptList.data = temp;
            wasteReceiptList.length = state.wasteReceiptList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          wasteReceiptList.offset = wasteReceiptList.length;
        }
      }
  
      wasteReceiptList.isLoading = false;
      wasteReceiptList.total = action.data.total;
      wasteReceiptList.hasMore = false;
      
      if(wasteReceiptList.length < wasteReceiptList.total)
      {
        wasteReceiptList.hasMore = true;
      }

      return {
        ...state,
        isLoadingWasteReceiptList: false,
        isLoadWasteReceiptListDone: true,
        wasteReceiptList,
        wasteReceiptListFilter: action.data
      };
    }

    case actionTypes.LOAD_WASTE_RECEIPT_LIST_FAIL:{
      let wasteReceiptList = cloneDeep(state.wasteReceiptList);
      wasteReceiptList.isLoading = false;

      return {
        ...state,
        wasteReceiptList,
        isLoadingWasteReceiptList: false,
        isLoadWasteReceiptListDone: true,
      };
    }



    case actionTypes.GET_WASTE_RECEIPT_DUMP_WASTE: {
      return {
        ...state,
        wasteReceiptDumpWaste: null,
        isLoadingWasteReceipt: true,
      };
    }

    case actionTypes.GET_WASTE_RECEIPT_DUMP_WASTE_SUCCESS: {
      return {
        ...state,
        wasteReceiptDumpWaste: action.data,
        isLoadingWasteReceipt: false,
      };
    }

    case actionTypes.GET_WASTE_RECEIPT_DUMP_WASTE_FAIL: {
      return {
        ...state,
        isLoadingWasteReceipt: false,
      };
    }   
    

    case actionTypes.LOAD_PURCHASE_BILL_MONTH: {
      return {
        ...state,
        purchaseBillMonth: {},
        isLoadingPurchaseBillMonth: true,
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_MONTH_SUCCESS: {
      return {
        ...state,
        purchaseBillMonth: action.data,
        isLoadingPurchaseBillMonth: false,
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_MONTH_FAIL: {
      return {
        ...state,
        isLoadingPurchaseBillMonth: false,
      };
    }    
    



    case actionTypes.LOAD_PURCHASE_BILL_SUMMARY: {
      return {
        ...state,
        purchaseBillSummary: null,
        isLoadingPurchaseBillSummary: true,
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_SUMMARY_SUCCESS: {
      return {
        ...state,
        purchaseBillSummary: action.data,
        isLoadingPurchaseBillSummary: false,
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_SUMMARY_FAIL: {
      return {
        ...state,
        isLoadingPurchaseBillSummary: false,
      };
    }  
    

    case actionTypes.LOAD_STOCK_RECORD_SUMMARY: {
      return {
        ...state,
        stockRecordSummary: null,
        isLoadingStockRecordSummary: true,
      };
    }

    case actionTypes.LOAD_STOCK_RECORD_SUMMARY_SUCCESS: {
      return {
        ...state,
        stockRecordSummary: action.data,
        isLoadingStockRecordSummary: false,
      };
    }

    case actionTypes.LOAD_STOCK_RECORD_SUMMARY_FAIL: {
      return {
        ...state,
        isLoadingStockRecordSummary: false,
      };
    }  
    

    case actionTypes.LOAD_STOCK_RECORD_LIST:{
      let storeRecordList = cloneDeep(state.storeRecordList);
  
      storeRecordList.isLoading = true;

      if (action.data.offset === 0) {
        storeRecordList.data = [];
      }

      return {
        ...state,
        isLoadingStoreRecordList: true,
        storeRecordList,
        isRefreshingStoreRecordList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_STOCK_RECORD_LIST_SUCCESS: {
      let storeRecordList = {};
      
      if (action.data.limit === undefined) {
        storeRecordList = cloneDeep(state.storeRecordList);
        storeRecordList.data = action.data.data;
      } else {
        storeRecordList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          storeRecordList.data = action.data.data;
          storeRecordList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            storeRecordList.data = [...state.storeRecordList.data];
            storeRecordList.offset = action.data.offset;
          } else {
            let temp = [...state.storeRecordList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            storeRecordList.data = temp;
            storeRecordList.length = state.storeRecordList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          storeRecordList.offset = storeRecordList.length;
        }
      }
  
      storeRecordList.isLoading = false;
      storeRecordList.total = action.data.total;
      storeRecordList.hasMore = false;
      
      if(storeRecordList.length < storeRecordList.total)
      {
        storeRecordList.hasMore = true;
      }

      return {
        ...state,
        isLoadingStoreRecordList: false,
        storeRecordList
      };
    }

    case actionTypes.LOAD_STOCK_RECORD_LIST_FAIL:{
      let storeRecordList = cloneDeep(state.storeRecordList);
      storeRecordList.isLoading = false;

      return {
        ...state,
        storeRecordList,
        isLoadingStoreRecordList: false,
      };
    }


    case actionTypes.LOAD_MONTHLY_STOCK_REPORT: {
      return {
        ...state,
        monthlyStockReportList: [],
        isLoadingMonthlyStockReportList: true,
      };
    }

    case actionTypes.LOAD_MONTHLY_STOCK_REPORT_SUCCESS: {
      return {
        ...state,
        monthlyStockReportList: action.data,
        isLoadingMonthlyStockReportList: false,
      };
    }

    case actionTypes.LOAD_MONTHLY_STOCK_REPORT_FAIL: {
      return {
        ...state,
        isLoadingMonthlyStockReportList: false,
      };
    }  
    
    
    case actionTypes.GET_MONTHLY_STOCK_REPORT_DOC: {
      return {
        ...state,
        isLoadingMonthlyStockReportDoc: true,
      };
    }

    case actionTypes.GET_MONTHLY_STOCK_REPORT_DOC_SUCCESS: {
      return {
        ...state,
        isLoadingMonthlyStockReportDoc: false,
      };
    }

    case actionTypes.GET_MONTHLY_STOCK_REPORT_DOC_FAIL: {
      return {
        ...state,
        isLoadingMonthlyStockReportDoc: false,
      };
    }  


    case actionTypes.LOAD_MONTHLY_WASTE_REPORT: {
      return {
        ...state,
        monthlyWasteReportList: [],
        isLoadingMonthlyWasteReportList: true,
      };
    }

    case actionTypes.LOAD_MONTHLY_WASTE_REPORT_SUCCESS: {
      return {
        ...state,
        monthlyWasteReportList: action.data,
        isLoadingMonthlyWasteReportList: false,
      };
    }

    case actionTypes.LOAD_MONTHLY_WASTE_REPORT_FAIL: {
      return {
        ...state,
        isLoadingMonthlyWasteReportList: false,
      };
    }  
    
    
    case actionTypes.GET_MONTHLY_WASTE_REPORT_DOC: {
      return {
        ...state,
        isLoadingMonthlyWasteReportDoc: true,
      };
    }

    case actionTypes.GET_MONTHLY_WASTE_REPORT_DOC_SUCCESS: {
      return {
        ...state,
        isLoadingMonthlyWasteReportDoc: false,
      };
    }

    case actionTypes.GET_MONTHLY_WASTE_REPORT_DOC_FAIL: {
      return {
        ...state,
        isLoadingMonthlyWasteReportDoc: false,
      };
    }  
    
    
    case actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL: {
      return {
        ...state,
        dailySettlement: null,
        isLoadingDailySettlementDetail: true,
      };
    }

    case actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL_SUCCESS: {
      return {
        ...state,
        dailySettlement: action.data,
        isLoadingDailySettlementDetail: false,
      };
    }

    case actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL_FAIL: {
      return {
        ...state,
        isLoadingDailySettlementDetail: false,
      };
    } 
    
    
    case actionTypes.COMPLETE_DAILY_SETTLEMENT: {
      return {
        ...state,
        isUpdateDailySettlementSuccess: false,
        isUpdateDailySettlementFailed: false,
        isProcessUpdateDailySettlement: true,
      };
    }

    case actionTypes.COMPLETE_DAILY_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        isUpdateDailySettlementSuccess: true,
        isProcessUpdateDailySettlement: false,
      };
    }

    case actionTypes.COMPLETE_DAILY_SETTLEMENT_FAIL: {
      return {
        ...state,
        isUpdateDailySettlementFailed: true,
        isProcessUpdateDailySettlement: false,
      };
    } 
    

    default:
      return state;
  }
}
